import { useMutation, useQueryClient } from '@tanstack/react-query'

import { profileService } from 'lib/api/ProfileService'
import { useMessageActions } from 'lib/state'

export const useEmailChangeCancel = () => {
  const { addMessage } = useMessageActions()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => profileService.cancelProfileEmailChange(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['/profile/email'] })
      addMessage({
        type: 'success',
        autoCloseSeconds: 5,
        text: 'E-Mail-Änderung abgebrochen.'
      })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: 'E-Mail-Änderung konnte nicht abgebrochen werden.' })
    }
  })
}
